import React from 'react';
// eslint-disable-next-line no-unused-vars
import CountUp, { useCountUp } from 'react-countup';
import { fate, fmn, iphone_mockup, seeds } from '../../assets/home';
import { FaArrowRight } from 'react-icons/fa';
import { formatNumber } from '../../utils/number';

const Model = () => {
  useCountUp({
    ref: 'farmers',
    end: 1500,
    duration: 4,
    enableScrollSpy: true,
    suffix: '+',
    formattingFn: formatNumber
  });
  useCountUp({
    ref: 'farms',
    end: 10000,
    duration: 4,
    enableScrollSpy: true,
    formattingFn: formatNumber
  });
  useCountUp({
    ref: 'agents',
    end: 1000,
    duration: 4,
    enableScrollSpy: true,
    formattingFn: formatNumber
  });

  return (
    <section
      className="flex flex-col-reverse justify-around max-w-full px-5 pt-20 mx-auto lg:flex-row lg:px-20"
      id="model">
      <div className="lg:w-5/12">
        <h1 className="hidden text-5xl font-bold leading-snug lg:flex">Our Model</h1>
        <p className="mt-6">
          Leveraging on People and Technology, our goal is to find a sweet spot where everyone can
          be a part of the agriculture value chain, we provide agents ( regular individuals with
          zeal) a mobile app platform to source for and manage farms, while company get access to
          data and products.
        </p>
        <div className="flex flex-wrap items-start justify-between mt-10">
          <div className="flex flex-col text-center w-min sm:w-unset">
            <h1 className="text-4xl font-bold leading-normal lg:text-5xl xl:text-7xl" id="farms" />
            <p>Farm sourced</p>
          </div>
          <div className="flex flex-col text-center w-min sm:w-unset">
            <h1
              className="text-4xl font-bold leading-normal lg:text-5xl xl:text-7xl"
              id="farmers"
            />

            <p>Farmers engaged</p>
          </div>
          <div className="flex flex-col text-center w-min sm:w-unset">
            <h1 className="text-4xl font-bold leading-normal lg:text-5xl xl:text-7xl" id="agents" />
            <p>Agents</p>
          </div>
        </div>
        <div className="flex items-center justify-between gap-4 mt-4">
          <span>
            <img src={fate} alt="FATE" />
          </span>
          <span>
            <img src={fmn} alt="FMN" />
          </span>
          <span>
            <img src={seeds} alt="SEEDS" />
          </span>
        </div>
        <span className="flex items-center my-8 text-indigo-600 cursor-pointer">
          See all{' '}
          <span className="ml-3">
            <FaArrowRight />
          </span>
        </span>
      </div>
      <div className="lg:w-5/12 ">
        <img src={iphone_mockup} className="mx-auto" alt="Iphone mockup" />
      </div>
      <h1 className="visible mb-10 text-5xl font-bold leading-snug lg:hidden">Our Model</h1>
    </section>
  );
};

export default Model;

// const CountUpText = () => {
//   return <div />;
// };
