import React from 'react';
import PlayStoreButton from '../PlaystoreButton';
import appImage from '../../assets/home/app-bridge.png';

const DownloadApp = () => {
  return (
    <div className="flex items-center justify-between bg-[#F5F6FA] pt-24" id="download_app">
      <div>
        <img loading="lazy" src={appImage} />
      </div>
      <div className="flex flex-col items-center justify-center md:text-5xl text-3xl space-y-5">
        <div className="my-auto space-y-4">
          <div>
            <p className="font-bold">Download our Mobile App</p>
          </div>
          <div className="flex items-center justify-center">
            <PlayStoreButton link="https://play.google.com/store/apps/details?id=com.aladdin_app" />
          </div>
        </div>
      </div>

      <div />
    </div>
  );
};

export default DownloadApp;
