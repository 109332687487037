import React from 'react';
import Navbar from '../components/Navbar';
import AboutSection from '../components/Section/About';
import ContactSection from '../components/Section/Contact';
import FaqSection from '../components/Section/Faq';
import Footer from '../components/Footer';
import GallerySection from '../components/Section/Gallery';
import HeroSection from '../components/Section/Hero';
import ModelSection from '../components/Section/Model';
import NewsSection from '../components/Section/News';
import ProductSection from '../components/Section/Product';
import ValidationSection from '../components/Section/Validation';
import DownloadApp from '../components/Section/DownloadApp';

const Home = () => {
  return (
    <div className="max-w-full overflow-x-hidden bg-green-100">
      <Navbar />
      <HeroSection />
      <AboutSection />
      <ProductSection />
      <GallerySection />
      <ModelSection />
      <ValidationSection />
      <FaqSection />
      <ContactSection />
      <NewsSection />
      <DownloadApp />
      <Footer />
    </div>
  );
};

export default Home;
