/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaArrowRight } from 'react-icons/fa';
import {
  Kernel1,
  Kernel2,
  Kernel3,
  Shell1,
  Shell2,
  Shell3,
  Paddy1,
  Paddy2,
  Paddy3,
  Trucking1,
  Trucking2,
  Trucking3,
  Sourcing1,
  Sourcing2,
  Sourcing3
} from '../../assets/gallery';

const Gallery = () => {
  const [activeItem, setActiveItem] = useState('Kernel/Nuts');

  const data = {
    'Kernel/Nuts': {
      text: {
        subtitle: 'Nuts & Kernels',
        title: 'How to identify the Healthy and market ready Nuts'
      },
      images: [Kernel1, Kernel2, Kernel3]
    },
    Shells: {
      text: {
        subtitle: 'Shells ',
        title: 'Processing Nuts order, dry and rightly measured'
      },
      images: [Shell1, Shell2, Shell3]
    },
    'Paddy Rice': {
      text: {
        subtitle: 'Staple Food',
        title: 'Rice sustainability, Players and opportunities'
      },
      images: [Paddy1, Paddy2, Paddy3]
    },
    Trucking: {
      text: {
        subtitle: 'Transportation',
        title: 'Navigating Africa’s Distribution network, Security & Insurance'
      },
      images: [Trucking1, Trucking2, Trucking3]
    },
    Sourcing: {
      text: {
        subtitle: 'Sourcing',
        title: 'Finding the most remote of farms & its challenges'
      },
      images: [Sourcing1, Sourcing2, Sourcing3]
    }
  };

  /**
   * It checks if the activeItem is equal to the name passed in
   * @param name - The name of the item to check if it's active.
   */
  const checkActive = (name) => activeItem?.toLowerCase() === name?.toLowerCase();

  const ItemText = ({ name }) => {
    return (
      <div onClick={() => setActiveItem(name)} className="cursor-pointer">
        <p
          className={`${
            checkActive(name) ? 'text-green-400 font-bold' : 'text-black'
          } whitespace-nowrap`}>
          {name}
        </p>
        {checkActive(name) && <div className="w-12 h-1 bg-green-400" />}
      </div>
    );
  };

  ItemText.propTypes = {
    name: PropTypes.string.isRequired
  };

  return (
    <section className="max-w-full px-5 py-20 mx-auto lg:px-20">
      <h1 className="text-5xl font-bold leading-snug text-center">Activity Gallery</h1>
      <p className="mx-auto mt-6 text-center lg:w-3/5">
        A quick peek into our years of experience working with various farmers across different
        locations in Nigeria, covering procurement of different crops.
      </p>
      <div className="flex flex-wrap items-center justify-center px-5 mt-12 -mx-5 gap-5 lg:gap-10 lg:px-0 ">
        {['Kernel/Nuts', 'Shells', 'Paddy Rice', 'Trucking', 'Sourcing']?.map((item, idx) => (
          <ItemText name={item} key={`${item}-${idx}`} />
        ))}
      </div>
      <div className="grid grid-cols-1 grid-rows-3 gap-4 mt-16 lg:grid-rows-2 lg:grid-cols-3">
        <div className="relative hidden row-span-2 bg-green-60 lg:flex">
          <img src={data[activeItem]?.images[0]} className="object-cover w-full h-full" />

          <div className="absolute top-0 bottom-0 left-0 right-0 bg-green-60" />
        </div>
        <div className="relative flex col-span-1 bg-green-60 lg:hidden">
          <img src={data[activeItem]?.images[1]} className="object-cover w-full h-full" />
          <div className="absolute top-0 bottom-0 left-0 right-0 bg-green-60" />
        </div>
        <div className="relative col-span-1 bg-green-500">
          <div className="absolute inset-x-10 bottom-10">
            <h2 className="mb-4 font-bold text-white opacity-60">
              {data[activeItem]?.text?.subtitle}
            </h2>
            <p className="mb-6 text-2xl font-bold text-white">{data[activeItem]?.text?.title}</p>
            <span className="flex items-center text-white">
              Read more{' '}
              <span className="ml-3">
                <FaArrowRight />
              </span>
            </span>
          </div>
        </div>
        <div className="relative hidden col-span-1 bg-green-60 lg:flex">
          <img src={data[activeItem]?.images[1]} className="object-cover w-full h-full" />
          <div className="absolute top-0 bottom-0 left-0 right-0 bg-green-60" />
        </div>
        <div className="relative col-span-1 bg-green-60 lg:col-span-2">
          <img src={data[activeItem]?.images[2]} className="object-cover w-full h-full" />
          <div className="absolute top-0 bottom-0 left-0 right-0 bg-green-60" />
        </div>
      </div>
    </section>
  );
};

export default Gallery;
