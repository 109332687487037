import React from 'react';
import Slider from '../Slider';

const Product = () => {
  return (
    <section className="max-w-full px-5 py-20 mx-auto lg:px-20" id="products">
      <h1 className="text-3xl font-bold leading-snug text-center lg:text-5xl">
        Some Of Our Product Portfolio
      </h1>
      <p className="mx-auto mt-6 text-center lg:w-4/6">
        Our product itenary is constantly growing, from being a purely B2B company, we are fast
        delving into food innovations for the direct market, some of our dealership products
        include:
      </p>
      <div className="mt-10">
        <Slider />
      </div>
    </section>
  );
};

export default Product;
