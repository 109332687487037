import React from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import '../Leaflet/leaflet.css';

const Map = () => {
  const position = [6.723224571693217, 3.4838315754338756];

  return (
    <MapContainer
      center={position}
      zoom={13}
      scrollWheelZoom={false}
      style={{ width: '100%', height: '100%' }}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={position}>
        <Popup>Bridge Merchant Location</Popup>
      </Marker>
    </MapContainer>
  );
};

export default Map;
