import React, { useState } from 'react';
import { FaPlus } from 'react-icons/fa';

const index = ({ title, content }) => {
  const [visible, setVisible] = useState(false);
  return (
    <div className="p-4 mb-4 bg-gray-300 rounded-xl">
      <div className="flex justify-between">
        <h1 className="font-medium">{title}</h1>
        <div>
          <FaPlus
            className={`${visible && 'rotate-45'} text-xl cursor-pointer`}
            onClick={() => setVisible(!visible)}
          />
        </div>
      </div>
      {visible && <p className="mt-6">{content}</p>}
    </div>
  );
};

export default index;
