import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as PlayStoreLogo } from '../assets/svgs/google-play.svg';

const PlayStoreButton = ({ link }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="cursor-pointer"
      //   className="inline-flex items-center px-4 py-2 text-base font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
    >
      <PlayStoreLogo />
    </a>
  );
};

PlayStoreButton.propTypes = {
  link: PropTypes.string.isRequired
};

export default PlayStoreButton;
