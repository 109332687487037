import React from 'react';
import { data, extension, source, testing, transportation, warehouse } from '../../assets/home';

const About = () => {
  return (
    <section className="max-w-full px-5 py-10 mx-auto lg:py-20 lg:px-20" id="service">
      <h1 className="text-3xl font-bold leading-snug text-center lg:text-5xl">What We Do</h1>
      <p className="mx-auto mt-6 text-center lg:w-2/5">
        We work with companies, farmers and agent to improve the Agricultural supply chain, from
        sourcing through till delivery
      </p>
      <div className="flex flex-wrap px-5 pt-16 mx-auto lg:px-0 lg:w-10/12">
        <div className="lg:w-1/2">
          <div className="flex flex-col items-center mx-auto lg:items-start lg:flex-row lg:w-9/20">
            <div className="mb-4 lg:mr-7 lg:mb-0">
              <img src={source} alt="Farm Sourcing & Aggregation" />
            </div>
            <div className="text-center lg:text-left">
              <h2 className="mb-2 text-lg font-bold lg:text-2xl ">Farm Sourcing & Aggregation</h2>
              <p>
                No matter the location, we engage local farmers and gather their goods for supply en
                masse
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center mx-auto mt-12 lg:items-start lg:flex-row lg:w-9/20">
            <div className="mb-4 lg:mr-7 lg:mb-0">
              <img src={data} alt="Data Gathering" />
            </div>
            <div className="text-center lg:text-left">
              <h2 className="mb-2 text-lg font-bold lg:text-2xl">Data Gathering</h2>
              <p>
                Soil data, weather, yield, and other varying metrices gathered from different
                location for the public
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center mx-auto mt-12 lg:items-start lg:flex-row lg:w-9/20">
            <div className="mb-4 lg:mr-7 lg:mb-0">
              <img src={warehouse} alt="Storage & Warehousing" />
            </div>
            <div className="text-center lg:text-left">
              <h2 className="mb-2 text-lg font-bold lg:text-2xl">Storage & Warehousing</h2>
              <p>
                Storing excess yield in silo warehouses, properly conditioned to be sold on demand.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-12 lg:w-1/2 lg:mt-0">
          <div className="flex flex-col items-center mx-auto lg:items-start lg:flex-row lg:w-9/20">
            <div className="mb-4 lg:mr-7 lg:mb-0">
              <img src={transportation} alt="Transportation & Procurement" />
            </div>
            <div className="text-center lg:text-left">
              <h2 className="mb-2 text-lg font-bold lg:text-2xl">Transportation & Procurement</h2>
              <p>
                Packaging and delivery from farms to factory, rural to urban areas, covering last
                mile dispatches.
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center mx-auto mt-12 lg:items-start lg:flex-row lg:w-9/20">
            <div className="mb-4 lg:mr-7 lg:mb-0">
              <img src={extension} alt="Extension Services" />
            </div>
            <div className="text-center lg:text-left">
              <h2 className="mb-2 text-lg font-bold lg:text-2xl">Extension Services</h2>
              <p>
                Aiding farmers with resources like fertilizers, applica- tion for loans, machineries
                and grants
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center mx-auto mt-12 lg:items-start lg:flex-row lg:w-9/20">
            <div className="mb-4 lg:mr-7 lg:mb-0">
              <img src={testing} alt="Testing & Standardization" />
            </div>
            <div className="text-center lg:text-left">
              <h2 className="mb-2 text-lg font-bold lg:text-2xl">Testing & Standardization</h2>
              <p>
                Matching company specifications to produce quality, testing yeild and produces per
                time.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
