import React from 'react';
import { img_illustration } from '../../assets/home';
import Button from '../Button';

const Hero = () => {
  const handleGetStartedScroll = () => {
    const element = document.getElementById('download_app');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <section className="flex flex-col-reverse max-w-full px-5 pt-8 mx-auto lg:pb-24 pb:10 lg:px-20 lg:flex-row">
      <div className="lg:w-2/4">
        <h1 className="text-3xl font-bold lg:leading-snug lg:text-4xl xl:text-6xl lg:w-9/20">
          Curbing Post Harvest Wastage & Connecting the food market
        </h1>
        <p className="pt-8 lg:w-9/12">
          Linking small-holder farmers to urban markets and companies via AgTech and people,
        </p>
        <div className="grid grid-cols-2 gap-4 mt-4 lg:w-9/12">
          <Button className="col-span-2 px-10 py-3 lg:col-span-1" onClick={handleGetStartedScroll}>
            Get Started
          </Button>
          <Button className="col-span-2 px-10 py-3 lg:col-span-1" outline="true">
            Learn More
          </Button>
        </div>
        <div className="flex justify-between mt-20 text-green-400 uppercase lg:w-8/12">
          <div className="col-span-1">
            Farm
            <br />
            sourcing
          </div>
          <div className="col-span-1">
            Storage &<br />
            packaging
          </div>
          <div className="col-span-1">
            Supply
            <br />
            chain
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:items-end lg:w-2/4">
        <img
          src={img_illustration}
          alt="Illustration"
          // className=" lg:w-11/20 w-60 h-30"
          className="object-cover w-full mx-auto mb-5 lg:w-11/20 sm:w-4/5 lg:mb-0"
          style={{ maxWidth: 'unset' }}
        />
        <div className="justify-center hidden text-green-400 lg:mt-24 lg:justify-end lg:flex">
          <p className="mr-5 lg:mr-24">Info Desk</p>
          <p>+234 807 958 1798</p>
        </div>
      </div>
    </section>
  );
};

export default Hero;
