import React from 'react';
import Accordion from '../Accordion';

const Faq = () => {
  const data = [
    {
      title: 'Is Bridge Merchant the same as Aladinn?',
      content:
        'Aladinn is the new mobile app platform from Bridge Merchant (The Parent company), it represents our decentralization model to engage farmers, agents and companies, launching soon.'
    },
    {
      title: 'What other terms of partnership exist with Bridge Merchant?',
      content:
        'We are on a mission to redefine how agriculture is done, and to ensure food sustainability is a reality, and that mission requires lots of effort, we welcome innovations, ideas, applications to join us, investments, and so on, frankly there is a lot.'
    },
    {
      title: 'How does the company plan work for procurement and sourcing?',
      content:
        'Company can contact us directly to handle their procurement, or access data and buy directly from the Aladinn app. We provide several layers of checks to ensure complete satisfaction. '
    },
    {
      title: 'What do i get as Agent?',
      content:
        'Agents get training on the go, a unique app dashboard to source, manage and train farmers as well as keep data and provide other services. From successful sales, and services agents earn commission and payment'
    },
    {
      title: 'Can farmers also signup as Agents',
      content:
        'The Aladinn idea caters for farmers who may be illiterate/ semi-literate and have little to no internet access. For farmers that have these boxes check, you can also manage your own farms and others using Aladinn’s & Bridge Merchant’s resources.'
    },
    {
      title: 'Is direct procurement and last mile delivery avalable with Aladinn?',
      content:
        'About 1 week there will be results that we give to clients. Because after the client makes payments we will begin to share a task with our workers and do the work as quickly as possible'
    }
  ];

  const ltQuestions = data.map((question, index) => {
    return ++index % 2 !== 0 && <Accordion {...question} key={index} />;
  });

  const rtQuestions = data.map((question, index) => {
    return ++index % 2 === 0 && <Accordion {...question} key={index} />;
  });

  return (
    <section className="max-w-full px-5 mx-auto lg:px-20 lg:py-28 py-14">
      <h1 className="text-3xl font-bold leading-snug text-center lg:text-5xl">
        Frequently Asked Questions
      </h1>
      <p className="mx-auto mt-6 text-center lg:w-6/12">
        What are some questions people are asking, have more questions or enquiries, you can contact
        us.
      </p>
      <div className="grid justify-between grid-cols-2 gap-4 mx-auto mt-16 lg:w-5/6">
        <div className="col-span-2 lg:col-span-1">{ltQuestions}</div>
        <div className="col-span-2 lg:col-span-1">{rtQuestions}</div>
      </div>
    </section>
  );
};

export default Faq;
