import React, { Component } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './slider.css';
import { cowpea, paddyRice, wheat, sorghum, yam, corn } from '../../assets/home';
// import { FaAngleRight, FaAngleLeft } from 'react-icons/fa';

export default class index extends Component {
  render() {
    const data = [
      {
        id: 1,
        title: 'Cowpea',
        subtitle: 'Vigna unguiculata',
        img: cowpea
      },
      {
        id: 2,
        title: 'Paddy Rice',
        subtitle: 'Oryza sativa',
        img: paddyRice
      },
      {
        id: 3,
        title: 'Sorghum',
        subtitle: 'Sorghum bicolor',
        img: sorghum
      },
      {
        id: 4,
        title: 'Wheat',
        subtitle: 'Triticum',
        img: wheat
      },
      {
        id: 5,
        title: 'Yam',
        subtitle: 'Dioscorea Specie',
        img: yam
      },
      {
        id: 6,
        title: 'Maize',
        subtitle: 'Zea Mays',
        img: corn
      }
    ];

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
            infinite: true,
            arrows: false
          }
        }
      ]
    };

    return (
      <div className="w-full">
        <Slider {...settings}>
          {data?.map(({ id, title, subtitle, img }) => (
            <div key={id}>
              <div className="flex flex-col justify-center mx-2">
                <div className="w-full h-32 bg-black lg:h-64">
                  <img src={img} alt="Cowpea" className="w-full h-full opacity-70" />
                </div>
                <div className="mt-2 text-center">
                  <h2 className="sm:text-2xl font-bold">{title}</h2>
                  <p className="mt-2 text-xs">{subtitle}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}
