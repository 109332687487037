/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { GrClose } from 'react-icons/gr';
import { logo_name, facebook, instagram } from '../../assets/home';
import Button from '../Button';
import { scrollTo } from '../../utils';

const index = () => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div>
      <nav className="flex items-center justify-between gap-5 max-w-full px-5 py-8 mx-auto lg:px-20">
        <div>
          <img src={logo_name} alt="Bridge Merchant logo" />
        </div>
        <div className="items-center justify-between hidden gap-10 lg:flex">
          <div className="flex text-base ">
            <a href="#">
              <p className="font-medium text-green-400 cursor-pointer hover:opacity-70 scroll-smooth">
                Home
              </p>
            </a>

            <p
              className="ml-8 font-medium cursor-pointer hover:opacity-70"
              onClick={() => {
                scrollTo({ id: 'products' });
              }}>
              About
            </p>

            <p
              className="ml-8 font-medium cursor-pointer hover:opacity-70"
              onClick={() => scrollTo({ id: 'service' })}>
              Services
            </p>

            <p
              className="ml-8 font-medium cursor-pointer hover:opacity-70"
              onClick={() => scrollTo({ id: 'model' })}>
              How It Works
            </p>

            <p
              className="ml-8 font-medium cursor-pointer hover:opacity-70"
              onClick={() => scrollTo({ id: 'contact' })}>
              Contact Us
            </p>
          </div>
          <div className="flex items-center">
            <span className="flex items-center justify-center w-10 h-10 mr-4 bg-green-500 rounded-full cursor-pointer">
              <img src={instagram} alt="Instagram Icon" />
            </span>
            <span className="flex items-center justify-center w-10 h-10 mr-4 bg-green-500 rounded-full cursor-pointer">
              <img src={facebook} alt="Facebook Icon" />
            </span>
            <Button onClick={() => scrollTo({ id: 'contact' })}>Be an Agent</Button>
          </div>
        </div>
        <div className="flex lg:hidden">
          {showMenu ? (
            <GrClose onClick={() => setShowMenu((show) => !show)} size={25} />
          ) : (
            <AiOutlineMenu onClick={() => setShowMenu((show) => !show)} size={25} />
          )}
        </div>
      </nav>
      {showMenu && (
        <div className="flex flex-col items-end justify-end px-5 bg-slate-200">
          <p
            className="pb-3 ml-8 font-medium cursor-pointer hover:opacity-70"
            onClick={() => {
              scrollTo({ id: 'products' });
              setShowMenu(false);
            }}>
            About
          </p>

          <p
            className="pb-3 ml-8 font-medium cursor-pointer hover:opacity-70"
            onClick={() => {
              scrollTo({ id: 'service' });
              setShowMenu(false);
            }}>
            Services
          </p>

          <p
            className="pb-3 ml-8 font-medium cursor-pointer hover:opacity-70"
            onClick={() => {
              scrollTo({ id: 'model' });
              setShowMenu(false);
            }}>
            How It Works
          </p>

          <p
            className="pb-3 ml-8 font-medium cursor-pointer hover:opacity-70"
            onClick={() => {
              scrollTo({ id: 'contact' });
              setShowMenu(false);
            }}>
            Contact Us
          </p>
          <Button
            onClick={() => {
              scrollTo({ id: 'contact' });
              setShowMenu(false);
            }}
            className="mb-3">
            Be an Agent
          </Button>
        </div>
      )}
    </div>
  );
};

export default index;
