import React, { useState } from 'react';
import { FaAngleRight, FaAngleLeft } from 'react-icons/fa';
import ImgSrc from '../../assets/home/validate_bg.png';

const Validation = () => {
  const data = [
    {
      heading: '2nd Runner, Agro Hack 2022',
      details:
        'Reviewing the challenges of the Agricultural industry, working with mentors and experts in the industry and competing for the most sustainable solution with the highest impact, the competition selected more than 50 startups from southern and nothern Nigeria each, critically analysing our solution and optimizing the outcome',
      title: 'Hackathon',
      organization: 'Enterprise Development Center'
    },
    {
      heading: 'Agribusiness Accelerator, FATE Foundation 2021',
      details:
        'Solutions are about models, finding the sweet spot between building a local viable business with a good enough framework to easily go global while solving the local problem. Understanding the african agribusiness space and it’s challenges',
      title: 'Accelerator',
      organization: 'Fate foundation'
    },
    {
      heading: 'Lead Africa , Top 20 Nigerian Business 2021',
      details:
        'Competing for the most innovative startup ideas and models in Nigeria for a support grant from the German government of wattenbattung and SEZ. Pitching, showing business viability alongsides a 2 day training.',
      title: 'Competition',
      organization: 'Lead Africa / Startup Afrika'
    },
    {
      heading: 'Project Sparrow',
      details:
        'Competing for the most innovative startup ideas and models in Nigeria for a support grant from the German government of wattenbattung and SEZ. Pitching, showing business viability alongsides a 2 day training.',
      title: 'Training',
      organization: 'Wennovation Hub/ USAID'
    }
  ];
  const [slideIndex, setSlideIndex] = useState(0);
  const prev = () => {
    data && slideIndex === 0 ? setSlideIndex(data.length - 1) : setSlideIndex(slideIndex - 1);
  };
  const next = () =>
    data && slideIndex === data.length - 1 ? setSlideIndex(0) : setSlideIndex(slideIndex + 1);

  return (
    <section className="flex flex-col max-w-full mx-auto lg:flex-row">
      <div
        className="flex items-center py-20 lg:w-1/2 lg:py-0"
        // eslint-disable-next-line no-undef
        style={{ backgroundImage: `url(${ImgSrc})` }}>
        <h2 className="w-2/4 mx-auto text-3xl sm:text-5xl font-bold leading-normal">
          Our Validation and Previous programs.
        </h2>
      </div>
      <div className="px-5 py-10 sm:px-16 sm:py-32 lg:w-1/2">
        <div className="flex justify-end mb-14">
          <span className="p-2 text-3xl cursor-pointer" onClick={prev}>
            <FaAngleLeft />
          </span>
          <span className="p-2 text-3xl cursor-pointer" onClick={next}>
            <FaAngleRight />
          </span>
        </div>
        <h1 className="mb-1 text-2xl font-bold">{data[slideIndex].heading}</h1>
        <p>{data[slideIndex].details}</p>
        <div className="flex mt-12">
          <div className="w-20 h-20 bg-green-300 rounded-full"></div>
          <div className="flex flex-col justify-center mt-1 ml-6">
            <h2 className="font-bold mb-0.5">{data[slideIndex].title}</h2>
            <p>{data[slideIndex].organization}</p>
          </div>
        </div>
        <div className="flex justify-end mt-20">
          <div className="w-10 h-10 bg-green-300 rounded-full"></div>
          <div className="w-10 h-10 ml-2 bg-green-300 rounded-full"></div>
          <div className="w-10 h-10 ml-2 bg-green-300 rounded-full"></div>
          <div className="w-10 h-10 ml-2 bg-green-300 rounded-full"></div>
        </div>
      </div>
    </section>
  );
};

export default Validation;
