import React from 'react';
import { logo_name } from '../../assets/home';
import {
  FaFacebook,
  FaLinkedin,
  FaTwitter,
  FaInstagram,
  FaMailBulk,
  FaMapMarkerAlt,
  FaPhoneAlt
} from 'react-icons/fa';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Button from '../Button';

const index = () => {
  const emailSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Required')
  });

  return (
    <footer className="flex flex-wrap justify-between max-w-full gap-10 px-5 pt-8 pb-20 mx-auto lg:flex-nowrap lg:px-20">
      <div className="w-full lg:w-1/4 ">
        <div className="h-8 mb-6">
          <img src={logo_name} alt="Bridge merchant" className="w-auto h-full" />
        </div>
        <p className="mb-10">
          Bridge Merchant Enterprises is an AgTech startup committed to eliminating Post harvest
          wastage and promoting food sustainability.
        </p>
        <p className="hidden mx-auto lg:flex">Copyright Bridge Merchant &#169; 2022</p>
      </div>
      <div className="w-full break-all lg:w-1/4">
        <h3 className="mb-6 font-bold text-green-400">Get in Touch</h3>
        <div className="flex mb-4">
          <span className="mr-6 text-lg text-green-400">
            <FaMapMarkerAlt />
          </span>
          <span>
            <p>24 Butterfly estate, Agric, Ikorodu, Lagos, Nigeria</p>
          </span>
        </div>
        <div className="flex mb-4">
          <span className="mr-6 text-lg text-green-400">
            <FaMailBulk />
          </span>
          <span>
            <p>hello@bridgemerchant.com</p>
          </span>
        </div>
        <div className="flex mb-4">
          <span className="mr-6 text-lg text-green-400">
            <FaPhoneAlt />
          </span>
          <span>
            <p>+234 (0) 807 958 1798</p>
          </span>
        </div>
      </div>
      <div className="w-full lg:w-1/4 ">
        <div className="flex mb-6">
          <span className="flex items-center justify-center w-10 h-10 text-2xl text-green-400 rounded-full">
            <FaFacebook />
          </span>
          <span className="flex items-center justify-center w-10 h-10 ml-4 text-2xl text-green-400 rounded-full">
            <FaLinkedin />
          </span>
          <span className="flex items-center justify-center w-10 h-10 ml-4 text-2xl text-green-400 rounded-full">
            <FaInstagram />
          </span>
          <span className="flex items-center justify-center w-10 h-10 ml-4 text-2xl text-green-400 rounded-full">
            <FaTwitter />
          </span>
        </div>
        <p>
          Connect with us on Social media, or sign up to our newsletter to follow us on this
          mission.
        </p>
      </div>
      <div className="w-full lg:w-1/4 ">
        <h2 className="font-bold text-green-400">Join a Newsletter</h2>
        <Formik
          initialValues={{
            email: ''
          }}
          validationSchema={emailSchema}
          onSubmit={(values) => {
            console.log(values);
          }}>
          {({ errors, touched }) => (
            <Form>
              <div className="flex flex-col w-full my-8">
                <label htmlFor="email">Email</label>
                <Field
                  name="email"
                  id="email"
                  className="p-4 mt-2 border border-green-500 border-solid"
                  placeholder="Enter you email address"
                />
                {errors.email && touched.email ? (
                  <p className="text-red-600">{errors.email}</p>
                ) : null}
              </div>
              <Button>Subscribe</Button>
            </Form>
          )}
        </Formik>
      </div>
      <p className="flex mx-auto lg:hidden">
        Copyright Bridge Merchant &#169; {new Date().getFullYear()}
      </p>
    </footer>
  );
};

export default index;
