import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="p-5 text-sm text-gray-500">
      <p className="text-xl font-bold text-black">PRIVACY NOTICE</p>
      <p className="mb-8 text-sm">Last updated November 05, 2022</p>
      This privacy notice for Bridge Merchant Enterprises (doing business as Bridge Merchant){' '}
      {`('Bridge Merchant', 'we', 'us', or 'our',)`}, describes how and why we might collect, store,
      use, and/or share{` ('process')`} your information when you use our services {`('Services')`},
      such as when you:
      <ul className="gap-2 my-3 ml-6 list-disc">
        <li className="mb-2">
          Visit our website at {` `}
          <p className="inline text-blue-300">{` `}http://www.bridgemerchant.com,</p>
          or any website of ours that links to this privacy notice
        </li>
        <li className="mb-2">
          Download and use our mobile application (Aladinn), or any other application of ours that
          links to this privacy notice
        </li>
        <li className="mb-2">
          Engage with us in other related ways, including any sales, marketing, or events
        </li>
      </ul>
      <div className="mb-5">
        <p className="inline-block font-bold">Questions or concerns?</p>
        {` `}
        Reading this privacy notice will help you understand your privacy rights and choices. If you
        do not agree with our policies and practices, please do not use our Services. If you still
        have any questions or concerns, please contact us at hello@bridgemerchant.com.
      </div>
      <p className="mb-3 text-lg font-bold text-black">SUMMARY OF KEY POINTS</p>
      <p className="mb-3 italic">
        This summary provides key points from our privacy notice, but you can find out more details
        about any of these topics by clicking the link following each key point or by using our
        table of contents below to find the section you are looking for. You can also click{' '}
        <p className="inline text-blue-400 underline cursor-pointer">here</p> to go directly to our
        table of contents.
      </p>
      What personal information do we process? When you visit, use, or navigate our Services, we may
      process personal information depending on how you interact with Bridge Merchant and the
      Services, the choices you make, and the products and features you use. Click here to learn
      more. Do we process any sensitive personal information? We do not process sensitive personal
      information. Do we receive any information from third parties? We do not receive any
      information from third parties. How do we process your information? We process your
      information to provide, improve, and administer our Services, communicate with you, for
      security and fraud prevention, and to comply with law. We may also process your information
      for other purposes with your consent. We process your information only when we have a valid
      legal reason to do so. Click here to learn more. In what situations and with which parties do
      we share personal information? We may share information in specific situations and with
      specific third parties. Click here to learn more. How do we keep your information safe? We
      have organisational and technical processes and procedures in place to protect your personal
      information. However, no electronic transmission over the internet or information storage
      technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that
      hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our
      security and improperly collect, access, steal, or modify your information. Click here to
      learn more. What are your rights? Depending on where you are located geographically, the
      applicable privacy law may mean you have certain rights regarding your personal information.
      Click here to learn more. How do you exercise your rights? The easiest way to exercise your
      rights is by filling out our data subject request form available here, or by contacting us. We
      will consider and act upon any request in accordance with applicable data protection laws.
      Want to learn more about what Bridge Merchant does with any information we collect? Click here
      to review the notice in full. TABLE OF CONTENTS 1. WHAT INFORMATION DO WE COLLECT? 2. HOW DO
      WE PROCESS YOUR INFORMATION? 3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION? 4.
      DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? 5. HOW LONG DO WE KEEP YOUR INFORMATION? 6.
      HOW DO WE KEEP YOUR INFORMATION SAFE? 7. DO WE COLLECT INFORMATION FROM MINORS? 8. WHAT ARE
      YOUR PRIVACY RIGHTS? 9. CONTROLS FOR DO-NOT-TRACK FEATURES 10. DO CALIFORNIA RESIDENTS HAVE
      SPECIFIC PRIVACY RIGHTS? 11. DO WE MAKE UPDATES TO THIS NOTICE? 12. HOW CAN YOU CONTACT US
      ABOUT THIS NOTICE? 13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU? 1.
      WHAT INFORMATION DO WE COLLECT? Personal information you disclose to us In Short: We collect
      personal information that you provide to us. We collect personal information that you
      voluntarily provide to us when you register on the Services, express an interest in obtaining
      information about us or our products and Services, when you participate in activities on the
      Services, or otherwise when you contact us. Personal Information Provided by You. The personal
      information that we collect depends on the context of your interactions with us and the
      Services, the choices you make, and the products and features you use. The personal
      information we collect may include the following: names phone numbers email addresses mailing
      addresses job titles usernames passwords contact preferences contact or authentication data
      Sensitive Information. We do not process sensitive information. Application Data. If you use
      our application(s), we also may collect the following information if you choose to provide us
      with access or permission: Geolocation Information. We may request access or permission to
      track location-based information from your mobile device, either continuously or while you are
      using our mobile application(s), to provide certain location-based services. If you wish to
      change our access or permissions, you may do so in your {`device's`}settings. Mobile Device
      Access. We may request access or permission to certain features from your mobile device,
      including your mobile {`device's`}bluetooth, calendar, camera, contacts, microphone, sensors,
      sms messages, storage, and other features. If you wish to change our access or permissions,
      you may do so in your {`device's`}settings. Mobile Device Data. We automatically collect
      device information (such as your mobile device ID, model, and manufacturer), operating system,
      version information and system configuration information, device and application
      identification numbers, browser type and version, hardware model Internet service provider
      and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using
      our application(s), we may also collect information about the phone network associated with
      your mobile device, your mobile device’s operating system or platform, the type of mobile
      device you use, your mobile device’s unique device ID, and information about the features of
      our application(s) you accessed. Push Notifications. We may request to send you push
      notifications regarding your account or certain features of the application(s). If you wish to
      opt out from receiving these types of communications, you may turn them off in your
      {`device's`}settings. This information is primarily needed to maintain the security and
      operation of our application(s), for troubleshooting, and for our internal analytics and
      reporting purposes. All personal information that you provide to us must be true, complete,
      and accurate, and you must notify us of any changes to such personal information. Information
      automatically collected In Short: Some information — such as your Internet Protocol (IP)
      address and/or browser and device characteristics — is collected automatically when you visit
      our Services. We automatically collect certain information when you visit, use, or navigate
      the Services. This information does not reveal your specific identity (like your name or
      contact information) but may include device and usage information, such as your IP address,
      browser and device characteristics, operating system, language preferences, referring URLs,
      device name, country, location, information about how and when you use our Services, and other
      technical information. This information is primarily needed to maintain the security and
      operation of our Services, and for our internal analytics and reporting purposes. Like many
      businesses, we also collect information through cookies and similar technologies. The
      information we collect includes: Log and Usage Data. Log and usage data is service-related,
      diagnostic, usage, and performance information our servers automatically collect when you
      access or use our Services and which we record in log files. Depending on how you interact
      with us, this log data may include your IP address, device information, browser type, and
      settings and information about your activity in the Services (such as the date/time stamps
      associated with your usage, pages and files viewed, searches, and other actions you take such
      as which features you use), device event information (such as system activity, error reports
      (sometimes called {'crash dumps'}), and hardware settings). Device Data. We collect device
      data such as information about your computer, phone, tablet, or other device you use to access
      the Services. Depending on the device used, this device data may include information such as
      your IP address (or proxy server), device and application identification numbers, location,
      browser type, hardware model, Internet service provider and/or mobile carrier, operating
      system, and system configuration information. Location Data. We collect location data such as
      information about your {`device's`}location, which can be either precise or imprecise. How
      much information we collect depends on the type and settings of the device you use to access
      the Services. For example, we may use GPS and other technologies to collect geolocation data
      that tells us your current location (based on your IP address). You can opt out of allowing us
      to collect this information either by refusing access to the information or by disabling your
      Location setting on your device. However, if you choose to opt out, you may not be able to use
      certain aspects of the Services. 2. HOW DO WE PROCESS YOUR INFORMATION? In Short: We process
      your information to provide, improve, and administer our Services, communicate with you, for
      security and fraud prevention, and to comply with law. We may also process your information
      for other purposes with your consent. We process your personal information for a variety of
      reasons, depending on how you interact with our Services, including: To facilitate account
      creation and authentication and otherwise manage user accounts. We may process your
      information so you can create and log in to your account, as well as keep your account in
      working order. To deliver and facilitate delivery of services to the user. We may process your
      information to provide you with the requested service. To respond to user inquiries/offer
      support to users. We may process your information to respond to your inquiries and solve any
      potential issues you might have with the requested service. To send administrative information
      to you. We may process your information to send you details about our products and services,
      changes to our terms and policies, and other similar information. To fulfil and manage your
      orders. We may process your information to fulfil and manage your orders, payments, returns,
      and exchanges made through the Services. To request feedback. We may process your information
      when necessary to request feedback and to contact you about your use of our Services. To send
      you marketing and promotional communications. We may process the personal information you send
      to us for our marketing purposes, if this is in accordance with your marketing preferences.
      You can opt out of our marketing emails at any time. For more information, see{' '}
      {`'WHAT ARE YOUR
        PRIVACY RIGHTS?'`}{' '}
      below). To protect our Services. We may process your information as part of our efforts to
      keep our Services safe and secure, including fraud monitoring and prevention. To evaluate and
      improve our Services, products, marketing, and your experience. We may process your
      information when we believe it is necessary to identify usage trends, determine the
      effectiveness of our promotional campaigns, and to evaluate and improve our Services,
      products, marketing, and your experience. To comply with our legal obligations. We may process
      your information to comply with our legal obligations, respond to legal requests, and
      exercise, establish, or defend our legal rights. 3. WHEN AND WITH WHOM DO WE SHARE YOUR
      PERSONAL INFORMATION? In Short: We may share information in specific situations described in
      this section and/or with the following third parties. We may need to share your personal
      information in the following situations: Business Transfers. We may share or transfer your
      information in connection with, or during negotiations of, any merger, sale of company assets,
      financing, or acquisition of all or a portion of our business to another company. When we use
      Google Maps Platform APIs. We may share your information with certain Google Maps Platform
      APIs (e.g. Google Maps API, Places API). To find out more about Google’s Privacy Policy,
      please refer to this link. Affiliates. We may share your information with our affiliates, in
      which case we will require those affiliates to honour this privacy notice. Affiliates include
      our parent company and any subsidiaries, joint venture partners, or other companies that we
      control or that are under common control with us. Business Partners. We may share your
      information with our business partners to offer you certain products, services, or promotions.
      Offer Wall. Our application(s) may display a third-party hosted {'offer wall'}. Such an offer
      wall allows third-party advertisers to offer virtual currency, gifts, or other items to users
      in return for the acceptance and completion of an advertisement offer. Such an offer wall may
      appear in our application(s) and be displayed to you based on certain data, such as your
      geographic area or demographic information. When you click on an offer wall, you will be
      brought to an external website belonging to other persons and will leave our application(s). A
      unique identifier, such as your user ID, will be shared with the offer wall provider in order
      to prevent fraud and properly credit your account with the relevant reward. 4. DO WE USE
      COOKIES AND OTHER TRACKING TECHNOLOGIES? In Short: We may use cookies and other tracking
      technologies to collect and store your information. We may use cookies and similar tracking
      technologies (like web beacons and pixels) to access or store information. Specific
      information about how we use such technologies and how you can refuse certain cookies is set
      out in our Cookie Notice. 5. HOW LONG DO WE KEEP YOUR INFORMATION? In Short: We keep your
      information for as long as necessary to fulfil the purposes outlined in this privacy notice
      unless otherwise required by law. We will only keep your personal information for as long as
      it is necessary for the purposes set out in this privacy notice, unless a longer retention
      period is required or permitted by law (such as tax, accounting, or other legal requirements).
      No purpose in this notice will require us keeping your personal information for longer than
      the period of time in which users have an account with us. When we have no ongoing legitimate
      business need to process your personal information, we will either delete or anonymise such
      information, or, if this is not possible (for example, because your personal information has
      been stored in backup archives), then we will securely store your personal information and
      isolate it from any further processing until deletion is possible. 6. HOW DO WE KEEP YOUR
      INFORMATION SAFE? In Short: We aim to protect your personal information through a system of
      organisational and technical security measures. We have implemented appropriate and reasonable
      technical and organisational security measures designed to protect the security of any
      personal information we process. However, despite our safeguards and efforts to secure your
      information, no electronic transmission over the Internet or information storage technology
      can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers,
      cybercriminals, or other unauthorised third parties will not be able to defeat our security
      and improperly collect, access, steal, or modify your information. Although we will do our
      best to protect your personal information, transmission of personal information to and from
      our Services is at your own risk. You should only access the Services within a secure
      environment. 7. DO WE COLLECT INFORMATION FROM MINORS? In Short: We do not knowingly collect
      data from or market to children under 18 years of age. We do not knowingly solicit data from
      or market to children under 18 years of age. By using the Services, you represent that you are
      at least 18 or that you are the parent or guardian of such a minor and consent to such minor
      dependent’s use of the Services. If we learn that personal information from users less than 18
      years of age has been collected, we will deactivate the account and take reasonable measures
      to promptly delete such data from our records. If you become aware of any data we may have
      collected from children under age 18, please contact us at admin@bridgemerchant.com. 8. WHAT
      ARE YOUR PRIVACY RIGHTS? In Short: You may review, change, or terminate your account at any
      time. If you are located in the EEA or UK and you believe we are unlawfully processing your
      personal information, you also have the right to complain to your local data protection
      supervisory authority. You can find their contact details here:
      https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm. If you are
      located in Switzerland, the contact details for the data protection authorities are available
      here: https://www.edoeb.admin.ch/edoeb/en/home.html. Withdrawing your consent: If we are
      relying on your consent to process your personal information, which may be express and/or
      implied consent depending on the applicable law, you have the right to withdraw your consent
      at any time. You can withdraw your consent at any time by contacting us by using the contact
      details provided in the section {`'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'`} below.
      However, please note that this will not affect the lawfulness of the processing before its
      withdrawal nor, when applicable law allows, will it affect the processing of your personal
      information conducted in reliance on lawful processing grounds other than consent. Opting out
      of marketing and promotional communications: You can unsubscribe from our marketing and
      promotional communications at any time by clicking on the unsubscribe link in the emails that
      we send, or by contacting us using the details provided in the section{' '}
      {`'HOW
        CAN YOU CONTACT US ABOUT THIS NOTICE?'`}{' '}
      below. You will then be removed from the marketing lists. However, we may still communicate
      with you — for example, to send you service-related messages that are necessary for the
      administration and use of your account, to respond to service requests, or for other
      non-marketing purposes. Account Information If you would at any time like to review or change
      the information in your account or terminate your account, you can: Log in to your account
      settings and update your user account. Upon your request to terminate your account, we will
      deactivate or delete your account and information from our active databases. However, we may
      retain some information in our files to prevent fraud, troubleshoot problems, assist with any
      investigations, enforce our legal terms and/or comply with applicable legal requirements.
      Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If
      you prefer, you can usually choose to set your browser to remove cookies and to reject
      cookies. If you choose to remove cookies or reject cookies, this could affect certain features
      or services of our Services. To opt out of interest-based advertising by advertisers on our
      Services visit http://www.aboutads.info/choices/. If you have questions or comments about your
      privacy rights, you may email us at hello@bridgemerchant.com. 9. CONTROLS FOR DO-NOT-TRACK
      FEATURES Most web browsers and some mobile operating systems and mobile applications include a
      Do-Not-Track {`('DNT')`} feature or setting you can activate to signal your privacy preference
      not to have data about your online browsing activities monitored and collected. At this stage
      no uniform technology standard for recognising and implementing DNT signals has been
      finalised. As such, we do not currently respond to DNT browser signals or any other mechanism
      that automatically communicates your choice not to be tracked online. If a standard for online
      tracking is adopted that we must follow in the future, we will inform you about that practice
      in a revised version of this privacy notice. 10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY
      RIGHTS? In Short: Yes, if you are a resident of California, you are granted specific rights
      regarding access to your personal information. California Civil Code Section 1798.83, also
      known as the {`'Shine The Light'`} law, permits our users who are California residents to
      request and obtain from us, once a year and free of charge, information about categories of
      personal information (if any) we disclosed to third parties for direct marketing purposes and
      the names and addresses of all third parties with which we shared personal information in the
      immediately preceding calendar year. If you are a California resident and would like to make
      such a request, please submit your request in writing to us using the contact information
      provided below. If you are under 18 years of age, reside in California, and have a registered
      account with Services, you have the right to request removal of unwanted data that you
      publicly post on the Services. To request removal of such data, please contact us using the
      contact information provided below and include the email address associated with your account
      and a statement that you reside in California. We will make sure the data is not publicly
      displayed on the Services, but please be aware that the data may not be completely or
      comprehensively removed from all our systems (e.g. backups, etc.). CCPA Privacy Notice The
      California Code of Regulations defines a {`'resident'`} as: (1) every individual who is in the
      State of California for other than a temporary or transitory purpose and (2) every individual
      who is domiciled in the State of California who is outside the State of California for a
      temporary or transitory purpose All other individuals are defined as {`'non-residents'`}. If
      this definition of {`'resident'`} applies to you, we must adhere to certain rights and
      obligations regarding your personal information. What categories of personal information do we
      collect? We have collected the following categories of personal information in the past twelve
      (12) months:
    </div>
  );
};

export default PrivacyPolicy;
