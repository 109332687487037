import React from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { Blog1, Blog2, Blog3, Blog4 } from '../../assets/news';

const News = () => {
  const data = [
    {
      heading: 'Food production: How everyone can be a part',
      subheading: 'As part of the United Nations prior goal to make the world more sustainable...',
      img: Blog1
    },
    {
      heading: 'How to engage rural/local farmers.',
      subheading:
        '80% of the food consumed in Nigeria and a better part of subsahara Africa is produced by ...',
      img: Blog2
    },
    {
      heading: 'The Bridge Model, Our road to food sustainability in Africa.',
      subheading:
        'Can Africa be the largest producer of food in the world ? How far apart is the continent ...',
      img: Blog3
    },
    {
      heading: 'The major food deficit: A trans-generational problem.',
      subheading:
        'Hunger and poverty has been one of the biggest problems of man kind since anyone can remember ...',
      img: Blog4
    }
  ];

  /* Filtering the data array and returning the odd numbered items. */
  const ltNews = data.map(({ heading, subheading, img }, index) => {
    return (
      ++index % 2 !== 0 && (
        <div className="flex flex-col mb-4 lg:flex-row " key={index}>
          <div className="bg-green-300 h-60 min-w-52">
            <img src={img} className="w-full h-full" />
          </div>
          <div className="flex flex-col justify-between p-4 bg-white">
            <div>
              <h3 className="mb-4 text-2xl font-bold">{heading}</h3>
              <p>{subheading}</p>
            </div>
            <div>
              <span className="flex items-center font-bold text-green-400">
                Learn more{' '}
                <span className="ml-3">
                  <FaArrowRight />
                </span>
              </span>
            </div>
          </div>
        </div>
      )
    );
  });

  /* Filtering the data array and returning the even numbered items. */
  const rtNews = data.map(({ heading, subheading, img }, index) => {
    return (
      ++index % 2 === 0 && (
        <div className="flex flex-col mb-4 lg:flex-row " key={index}>
          <div className="bg-green-300 h-60 min-w-52">
            <img src={img} className="w-full h-full" />
          </div>
          <div className="flex flex-col justify-between p-4 bg-white">
            <div>
              <h3 className="mb-4 text-2xl font-bold">{heading}</h3>
              <p>{subheading}</p>
            </div>
            <div>
              <span className="flex items-center font-bold text-green-400">
                Learn more{' '}
                <span className="ml-3">
                  <FaArrowRight />
                </span>
              </span>
            </div>
          </div>
        </div>
      )
    );
  });

  return (
    <section className="max-w-full px-5 mx-auto lg:px-20 lg:py-28 py-14">
      <h1 className="text-3xl font-bold leading-snug text-center lg:text-5xl">Our Latest News</h1>
      <div className="grid grid-cols-2 gap-4 mt-10">
        <div className="col-span-2 lg:col-span-1">{ltNews}</div>
        <div className="col-span-2 lg:col-span-1">{rtNews}</div>
      </div>
    </section>
  );
};

export default News;
