import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaFacebookF, FaTwitter, FaInstagram, FaMediumM } from 'react-icons/fa';
import Map from '../Map';
import Button from '../Button';

const Contact = () => {
  const contactSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'First Name is too short!')
      .max(50, 'First Name is too long!')
      .required('Required'),
    lastName: Yup.string()
      .min(2, 'Last Name is too short!')
      .max(50, 'Last Name is too long!')
      .required('Required'),
    email: Yup.string().email('Invalid email address').required('Required'),
    option: Yup.string().required('Required'),
    message: Yup.string().min(10, 'Message is too short!').required('Please enter a message...')
  });

  const sendMessage = async (values, actions) => {
    actions.setSubmitting(true);
    try {
      const res = await axios.post(
        'https://aladinn-web-contact-api.onrender.com/mail',
        {
          ...values,
          about: values.option,
          firstname: values?.firstName,
          lastname: values?.lastName
        },
        {
          headers: {
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*'
          }
        }
      );

      if (res?.data?.status === 'success') {
        toast.success(
          'Thanks for contacting us a Bridge Merchant rep will be in touch with you shortly.'
        );
      }
    } catch (error) {
      toast.error(error?.message || 'Something went wrong, please try again');
    }
    actions.setSubmitting(false);
  };

  return (
    <section className="flex flex-col max-w-full mx-auto lg:flex-row" id="contact">
      <div className="flex items-center px-5 py-10 lg:w-1/2 lg:py-20 lg:px-0">
        <div className="flex flex-col mx-auto lg:w-9/12">
          <h1 className="text-3xl font-bold leading-snug lg:text-5xl">Contact Us</h1>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              option: '',
              message: ''
            }}
            validationSchema={contactSchema}
            onSubmit={(values, actions) => {
              sendMessage(values, actions);
            }}>
            {({ errors, touched, handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <div className="flex flex-col gap-4 mt-10 mb-6 lg:flex-row">
                  <div className="flex flex-col lg:w-1/2">
                    <label htmlFor="name">First Name</label>
                    <Field
                      name="firstName"
                      id="name"
                      className="p-4 mt-2 border border-green-500 border-solid"
                      placeholder="Enter you first name"
                    />
                    {errors.firstName && touched.firstName ? (
                      <p className="text-red-600">{errors.firstName}</p>
                    ) : null}
                  </div>
                  <div className="flex flex-col lg:w-1/2">
                    <label htmlFor="lname">Last Name</label>
                    <Field
                      name="lastName"
                      id="lname"
                      className="p-4 mt-2 border border-green-500 border-solid"
                      placeholder="Enter you last name"
                    />
                    {errors.lastName && touched.lastName ? (
                      <p className="text-red-600">{errors.lastName}</p>
                    ) : null}
                  </div>
                </div>
                <div className="flex gap-4 mb-10 ">
                  <div className="flex flex-col w-full">
                    <label htmlFor="email">Email</label>
                    <Field
                      name="email"
                      id="email"
                      className="p-4 pr-0 mt-2 border border-green-500 border-solid"
                      placeholder="Enter you email address"
                    />
                    {errors.email && touched.email ? (
                      <p className="text-red-600">{errors.email}</p>
                    ) : null}
                  </div>
                </div>
                <fieldset className="flex flex-wrap ">
                  <div className="flex flex-row items-center mb-6 mr-6">
                    <Field
                      type="radio"
                      className="absolute invisible"
                      name="option"
                      id="sourcing"
                      value="Sourcing/Aggregation"
                    />
                    <label htmlFor="sourcing" className="ml-6 font-bold text-gray-950">
                      Sourcing/Aggregation
                    </label>
                  </div>
                  <div className="flex flex-row items-center mb-6 mr-6">
                    <Field
                      type="radio"
                      className="absolute invisible"
                      name="option"
                      id="enquiry"
                      value="Enquiry"
                    />
                    <label htmlFor="enquiry" className="ml-6 font-bold text-gray-950">
                      Enquiry
                    </label>
                  </div>
                  <div className="flex flex-row items-center mb-6 mr-6">
                    <Field
                      type="radio"
                      className="absolute invisible"
                      name="option"
                      id="logistics"
                      value="Logistics"
                    />
                    <label htmlFor="logistics" className="ml-6 font-bold text-gray-950">
                      Logistics
                    </label>
                  </div>
                  <div className="flex flex-row items-center mb-6 mr-6">
                    <Field
                      type="radio"
                      className="absolute invisible"
                      name="option"
                      id="agent"
                      value="Agent Signup"
                    />
                    <label htmlFor="agent" className="ml-6 font-bold text-gray-950">
                      Agent Signup
                    </label>
                  </div>
                  <div className="flex flex-row items-center mb-6 mr-6">
                    <Field
                      type="radio"
                      className="absolute invisible"
                      name="option"
                      id="partnership"
                      value="Partnership"
                    />
                    <label htmlFor="partnership" className="ml-6 font-bold text-gray-950">
                      Partnership
                    </label>
                  </div>
                  <div className="flex flex-row items-center mb-6 mr-6">
                    <Field
                      type="radio"
                      className="absolute invisible"
                      name="option"
                      id="others"
                      value="Others"
                    />
                    <label htmlFor="others" className="ml-6 font-bold text-gray-950">
                      Others
                    </label>
                  </div>
                  {errors.option && touched.option ? (
                    <p className="text-red-600">{errors.option}</p>
                  ) : null}
                </fieldset>

                <div className="flex flex-col w-full my-4">
                  <label htmlFor="msg">Message</label>
                  <Field
                    name="message"
                    type=""
                    id="msg"
                    className="p-4 mt-2 border border-green-500 border-solid"
                    placeholder="Enter you message"
                  />
                  {errors.message && touched.message ? (
                    <p className="text-red-600">{errors.message}</p>
                  ) : null}
                </div>
                <Button
                  type="submit"
                  // onClick={() =>
                  //   toast.success(
                  //     'Thanks for contacting us a Bridge Merchant rep will be in touch with you shortly.'
                  //   )
                  // }
                  loading={isSubmitting}>
                  Send
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="relative items-center hidden bg-green-300 lg:flex lg:w-1/2">
        <Map />

        <div className="z-999 absolute flex flex-row justify-between p-6 mx-auto -translate-x-1/2 bg-white rounded-lg w-9/20 bottom-16 left-1/2">
          <div className="flex flex-col justify-center">
            <p>Map Street</p>
            <h3 className="mt-2 text-2xl font-bold">24 Butterfly Estate</h3>
          </div>
          <div className="flex flex-row gap-4">
            <div>
              <div className="flex">
                <span className="flex items-center justify-center w-6 h-6 text-base text-white bg-green-500 rounded-full">
                  <FaFacebookF />
                </span>
                <p className="ml-2 font-bold">Facebook</p>
              </div>
              <div className="flex mt-8">
                <span className="flex items-center justify-center w-6 h-6 text-base text-white bg-green-500 rounded-full">
                  <FaTwitter />
                </span>
                <p className="ml-2 font-bold">Twitter</p>
              </div>
            </div>
            <div>
              <div className="flex">
                <span className="flex items-center justify-center w-6 h-6 text-base text-white bg-green-500 rounded-full">
                  <FaInstagram />
                </span>
                <p className="ml-2 font-bold">Instagram</p>
              </div>
              <div className="flex mt-8">
                <span className="flex items-center justify-center w-6 h-6 text-base text-white bg-green-500 rounded-full">
                  <FaMediumM />
                </span>
                <p className="ml-2 font-bold">Medium</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
